import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/why',
    name: 'Why Should I?',
    component: () => import('../views/Why.vue')
  },
  {
    path: '/faq',
    name: "Frequently Asked Questions",
    component: () => import('../views/Faq.vue')
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: () => import('../views/PrivacyPolicy.vue')
  },
  {
    path: '/terms-of-service',
    name: 'Terms of Service',
    component: () => import('../views/TermsOfService.vue')
  },
  {
    path: '/certificate',
    name: 'Pledge Certificate',
    component: () => import('../views/Certificate.vue')
  },
  {
    path: '/learn',
    name: "Learn More",
    component: () => import('../views/Learn.vue')
  },
  {
    path: '/racism',
    name: 'Racism',
    component: () => import('../views/Racism.vue')
  },
  {
    path: '/bullying',
    name: 'Racism',
    component: () => import('../views/Bullying.vue')
  },
  {
    path: '/homeold',
    name: 'Home Old',
    component: () => import('../views/Homeold.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

import VueGtag from "vue-gtag";
Vue.use(VueGtag, {
  config: { id: "G-17PPN8YZ2J" }
}, router);

export default router
