<template>
  <v-card class="rounded-lg flex-grow-1" style="overflow: hidden" color="blue darken-3" dark>
    <v-container class="rounded-lg flex-grow-1" style="max-width: 1280px">
      <v-row justify="center">
        <v-col cols="10">
        <div class="text-h4">
          Every person that lives My One Rule makes the world better!
        </div>
          <!--
          <v-btn class="mb-6" color="black" @click="pledgeInfoModal = true" block large dark>
            MOR Pledge
          </v-btn>
          -->
          <div class="text-h6">
            Be part of the <strong class="text-decoration-underline">solution</strong>.  Join us now!
          </div>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="10">
          <v-form ref="pledgeForm" v-model="pledgeValid" :disabled="pledgeState != 0">
            <v-row justify="center">
              <v-col cols="12" md="6">
                <v-text-field
                 label="First Name" 
                 v-model="pledgeName" 
                 :rules="nameRules"
                 placeholder="Enter your first name."
                 background-color="white"
                 solo
                 light
                 required>
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                 label="Last Name"
                 v-model="pledgeInitial"
                 :rules="initialRules"
                 placeholder="Enter your last name."
                 background-color="white"
                 solo
                 light
                 required>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="12">
                <v-text-field 
                 label="Email" 
                 v-model="pledgeEmail" 
                 :rules="emailRules" 
                 placeholder="Enter your email address."
                 background-color="white"
                 solo
                 light
                 required>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="12">
                <v-text-field 
                 type="yeet" 
                 id="search" 
                 autocomplete="off"
                 :rules="placeRules" 
                 placeholder="Start typing your location to search." 
                 background-color="white"
                 solo
                 light
                 required>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="6">
                <v-btn color="white" :disabled="!canSubmit()" :loading="pledgeState == 1" v-on:click="submitPledgeForm()" block large light>
                  Submit
                  <v-icon>
                    mdi-draw
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn color="red" @click="shareModal = true" block large dark>
                  Share
                  <v-icon>
                    mdi-share-variant
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row justify="center" v-if="pledgeCounter">
              <v-col cols="10">
                <!--
                <v-chip class="mt-2" label light>
                  {{ pledgeCount }} Pledges
                </v-chip>
                -->
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="10">
                <div class="text-caption">
                  This site is protected by reCAPTCHA and the Google
                  <a class="orange--text" href="https://policies.google.com/privacy" aria-label="Google privacy policy.">Privacy Policy</a> and
                  <a class="orange--text" href="https://policies.google.com/terms" aria-label="Google terms of service.">Terms of Service</a> apply.
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-container>

    <!-- Social Sharing Dialog -->
    <v-dialog v-model="shareModal" persistent max-width="290">
      <v-card>
        <v-card-title class="justify-center">
          <h6 class="text-h6 text-center mb-2">
            Spread the Word
          </h6>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <ShareNetwork
            style="text-decoration: none"
            network="facebook"
            url="https://myonerule.com"
            title="Check out My One Rule!"
            hashtags="myonerule"
            >
            <v-btn class="mb-6" color="primary" block>
              Facebook
            </v-btn>
          </ShareNetwork>
          <ShareNetwork
            style="text-decoration: none"
            network="twitter"
            url="https://myonerule.com"
            title="Check out My One Rule!"
            hashtags="myonerule"
            >
            <v-btn class="mb-6" color="blue lighten-1" block dark>
              Twitter
            </v-btn>
          </ShareNetwork>
          <ShareNetwork
            style="text-decoration: none"
            network="email"
            url="https://myonerule.com"
            title="Check out My One Rule!"
            hashtags="myonerule"
            >
            <v-btn color="black" block dark>
              Email
            </v-btn>
          </ShareNetwork>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn color="green darken-1" text @click="shareModal = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="pledgeInfoModal" persistent max-width="720">
      <v-card class="px-4">
        <v-card-title class="headline justify-center">
          My One Rule Pledge
        </v-card-title>
        <v-card-text class="text-body-1">
          I pledge to:<br>
          Make the world a better place.<br>
          Make my neighborhood a better place.<br> 
          Do what's necessary to be remembered as good.<br> 
          I will do this by treating others the way that I want to be treated.<br>
          That's My One Rule
        </v-card-text>
        <v-img :src="require('../assets/img/cert/cert-preview.jpg')">

        </v-img>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="pledgeInfoModal = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Error Dialog -->
    <v-dialog v-model="errorModal" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">
          Form Errors
        </v-card-title>
        <v-card-text v-for="(error, index) in pledgeErrors" :key="index">{{ error }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="errorModal = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
  import gmapsInit from '../utils/gmaps';
  import { EventBus } from '../main';

  export default {
    name: 'PledgeForm',

    async mounted() {
      try {
        const google = await gmapsInit();

        // Setup location autocomplete.
        var options = {
            types: ['(cities)']
        };
        var autocomplete = new google.maps.places.Autocomplete(document.getElementById("search"), options);

        // Listen for the pledge form's location update event.
        EventBus.$on('markers-ready', (data) => {
          this.pledgeCount = data.count;
          this.pledgeCounter = true;
        });

        // Bind listender to update the map.
        autocomplete.addListener('place_changed', () => {
          var place = autocomplete.getPlace();
          this.pledgePlaceId = place.place_id;
          console.log(this.pledgePlaceId);
          EventBus.$emit('place-updated', { location: place.geometry.location, id: place.place_id });
        });
      } catch (error) {
        console.error(error);
      }
    },

    data: () => ({
      // Contact form stuffs.
      recaptcha: null,
      pledgeValid: false,
      pledgeState: 0,  // 0 = Ready | 1 = Submitted/Waiting | 2 = Success
      pledgeErrors: [],
      pledgeOverlay: false,
      pledgeName: "",
      pledgeInitial: "",
      pledgeEmail: "",
      pledgePlaceId: "none",
      pledgeInfoModal: false,

      // Thank-you modal.
      errorModal: false,

      // Social links.
      socialButtons: [
        { label: "Facebook",  color: "blue darken-2",       icon: "mdi-facebook",  href: "https://www.facebook.com/my1rule/" },
        { label: "Instagram", color: "purple darken-1",     icon: "mdi-instagram", href: "https://www.instagram.com/my1rule/" },
        { label: "Twitter",   color: "light-blue darken-1", icon: "mdi-twitter",   href: "https://twitter.com/MyOneRule" }
      ],

      // Optional pledge counter.
      pledgeCounter: false,
      pledgeCount: 0,

      // Sharing modal.
      shareModal: false,

      nameRules: [
        v => !!v || 'Please enter your first name.',
        v => (v && v.length <= 32) || 'Name must be less than 32 characters',
      ],

      initialRules: [
        v => !!v || 'Please enter your last initial.',
        v => (v && v.length <= 128) || 'Last name must be 128 characters or less.',
      ],

      placeRules: [
        v => !!v || 'Please enter your town, city, or locality.',
        v => (v && v.length <= 64) || 'Locality must be less than 64 characters',
      ],

      emailRules: [
        v => !!v || 'Please enter your email address.',
        v => /.+@.+\..+/.test(v) || 'Please enter a valid E-mail address.',
      ],
    }),
    methods: {
      canSubmit: function () {
        return (this.pledgeValid && this.pledgeState == 0) && this.pledgePlaceId != "";
      },
      getFormattedDate: function() {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth()+1; //As January is 0.
        var yyyy = today.getFullYear();

        if(dd<10) dd='0'+dd;
        if(mm<10) mm='0'+mm;
        return (mm+'/'+dd+'/'+yyyy);
      },
      submitPledgeForm: function () {
        //Don't submit yet
        event.preventDefault();
        
        // Secret key provided by Google
        const apiString = 'https://us-central1-waldock-my-one-rule.cloudfunctions.net/api/submitPledge';

        // Set contact form to waiting state.
        this.pledgeState = 1;

        // Generate token
        this.$recaptcha('login')
          .then((token) => {
            this.$http.post(`${apiString}`, {
              token: token,
              name: this.pledgeName,
              initial: this.pledgeInitial.substring(0, 1).toUpperCase(),
              email: this.pledgeEmail,
              placeid: this.pledgePlaceId
            })
              .then((response) => {
                console.log(response.status);
                const success = response.status == 200;

                // Take action here based on score.
                if (success) {
                  this.pledgeModal = true;
                  this.pledgeErrors = [];
                  this.pledgeState = 2;
                  this.$router.push({path: "/certificate", query: { name: `${this.pledgeName} ${this.pledgeInitial}.`, date: this.getFormattedDate() }});
                  this.$confetti.start();
                  setTimeout(() => {
                    this.$confetti.stop();
                  }, 1000);
                }
              })
              .catch((error) => {
                const errors = error.response.data.errors;
                console.log(errors);
                this.pledgeErrors = [];
                errors.forEach((item) => {
                  if (!item.msg || item.msg == '') {
                    this.pledgeErrors.push("Failed reCaptcha validation. Disable any AdBlockers and try again.");
                  }
                  else {
                    this.pledgeErrors.push(item.msg);
                  }
                });
                this.errorModal = true;
                this.pledgeState = 0;
              });
          });
      }
    },
  }
</script>