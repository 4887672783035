<template>
  <header>
    <!-- Global Navbar -->
    <v-app-bar style="z-index: 1000" color="grey lighten-3" light app>
      <v-toolbar-title class="mx-4" style="cursor: pointer" @click="$router.push('/')">
        <v-img width="64px" :src="require('../assets/img/logo/mor-logo-hand-sm.png')" :aspect-ratio="4/3" contain></v-img>
      </v-toolbar-title>
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn v-for="item in primaryNav" :key="item.label" :href="item.href" text>
          {{ item.label }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn :color="social.color" v-for="(social, i) in socialButtons" :key="'social-btn-' + i" :href="social.href" text>
          <v-icon dark>
            {{ social.icon }}
          </v-icon>
        </v-btn>
      </v-toolbar-items>
      <span class="hidden-md-and-up">
        <v-app-bar-nav-icon @click="sidebar = !sidebar"></v-app-bar-nav-icon>
      </span>
    </v-app-bar>

    <!-- Mobile-only Sidebar -->
    <v-navigation-drawer style="z-index: 1001" v-model="sidebar" disable-resize-watcher right app>
      <v-list dense nav>
        <v-list-item v-for="item in primaryNav" :key="item.label" :href="item.href">
          <v-list-item-content>
            <v-list-item-title>
              {{ item.label }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-btn :color="social.color" v-for="(social, i) in socialButtons" :key="'social-btn-' + i" :href="social.href" text>
        <v-icon dark>
          {{ social.icon }}
        </v-icon>
      </v-btn>
    </v-navigation-drawer>
  </header>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      websiteTitle: "My One Rule",
      sidebar: false,

      // Navigation links defined here.
      // For internal page links, just use a '/' followed by the router destination, i.e '/about' or '/home'.
      // External links should use a fully qualified URL, i.e 'https://google.com/'.
      primaryNav: [
        { label: "Home",  href: "/" },
        { label: "About", href: "/about" },
        { label: "Why",   href: "/why" },
        { label: "Learn", href: "/learn" },
        { label: "Shop",  href: "https://shop.myonerule.com/" }
      ],

      // Social links.
      socialButtons: [
        { label: "Facebook",  color: "blue darken-2",       icon: "mdi-facebook",   href: "https://www.facebook.com/my1rule/" },
        { label: "Instagram", color: "purple darken-1",     icon: "mdi-instagram",  href: "https://www.instagram.com/my1rule/" },
        { label: "Twitter",   color: "light-blue darken-1", icon: "mdi-twitter",    href: "https://twitter.com/MyOneRule" },
        { label: "TikTok",    color: "grey darken-2",      icon: "mdi-music-note", href: "https://www.tiktok.com/@myonerule?lang=en" }
      ],
    };
  },
};
</script>