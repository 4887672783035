import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';

import axios from 'axios';
import VueAxios from 'vue-axios';
import { VueReCaptcha } from 'vue-recaptcha-v3'

// AOS Imports
import AOS from 'aos'
import 'aos/dist/aos.css'

// Confetti
import VueConfetti from 'vue-confetti';

// Social sharing.
import VueSocialSharing from 'vue-social-sharing';
Vue.use(VueSocialSharing);

// Printing
import VueHtmlToPaper from "vue-html-to-paper";
const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"]
};


Vue.use(VueHtmlToPaper, options);

//Animations
import VAnimateCss from 'v-animate-css';

Vue.use(VAnimateCss);

Vue.config.productionTip = false

export const EventBus = new Vue();

Vue.use(VueAxios, axios);

Vue.use(VueReCaptcha, {
  siteKey: '6LdOX_0ZAAAAADFtZOgNXRNmnpzdjRyEJqkuELTu'
});

Vue.use(VueConfetti);

new Vue({
  router,
  vuetify,
  created () {
    AOS.init();
  },
  render: h => h(App)
}).$mount('#app')
