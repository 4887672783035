<template>
  <v-sheet class="d-flex flex-column flex-grow-1" style="position: relative; overflow: hidden;" color="light-blue lighten-4" light>
    <div id="pledge-map" ref="pledge-map" class="flex-grow-1"></div>
    <!-- Loading Indicator -->
    <v-overlay :value="loadIndicator" opacity="0.5" absolute>
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </v-overlay>
    <v-card color="white">
      <!--
      <v-card-title class="justify-center">
        Latest Pledge<br>
        {{ latestPledge.name }}
      </v-card-title>
      -->
    </v-card>
  </v-sheet>
</template>

<script>
import gmapsInit from '../utils/gmaps';
import { EventBus } from '../main';

export default {
  name: 'PledgeMap',
  async mounted() {
    try {
      const google = await gmapsInit();
      this.mapInstance = new google.maps.Map(document.getElementById("pledge-map"), {
        center: { lat: 37.090240, lng: -95.712891 },
        streetViewControl: false,
        zoom: 4
      });

      // Listen for the pledge form's location update event.
      EventBus.$on('place-updated', (data) => {
        this.mapInstance.setCenter(data.location);
        this.mapInstance.setZoom(10);
      });

      // Request marker data.
      var pledgeCount = 0;
      this.$http.get("https://us-central1-waldock-my-one-rule.cloudfunctions.net/api/getPledges").then((response) => {
        for (var i in response.data) {
          var place = response.data[i];

          if (place.location == "none") continue;

          // Create the marker for each place.
          var marker = new google.maps.Marker({
            position: place.location,
            map: this.mapInstance,
            title: place.name,
          });

          // Create the info window for each marker.
          var windowHTML = "";
          for (var j in place.pledges) {
            pledgeCount++;
            var pledge = place.pledges[j];
            windowHTML += `<li>${pledge.name}</li>`;
          }

          var window = new google.maps.InfoWindow({
            content: `
              <h3>${place.name}, ${place.region}</h3>
              <ol class="text-left">
                ${windowHTML}
              </ol>
            `
          });

          // Add marker and info window.
          this.mapMarkers.push(marker);
          this.mapWindows.push(window);
        }

        // Link all markers and windows.
        this.mapMarkers.forEach(( marker, i ) => {
          var window = this.mapWindows[i];
          marker.addListener('click', () => {
            this.mapWindows.forEach((w) => {
              w.close();
            });
            window.open(this.mapInstance, marker);
          });
        });

        // Disable the loading indicator.
        EventBus.$emit('markers-ready', {
          count: pledgeCount
        });
        this.loadIndicator = false;
      });
      this.$http.get("https://us-central1-waldock-my-one-rule.cloudfunctions.net/api/latestPledge").then((response) => {
        this.latestPledge = response.data;
      });
    } catch (error) {
      console.error(error);
    }
  },
  data: () => ({
    mapInstance: null,
    mapMarkers: [],
    mapWindows: [],
    loadIndicator: true,
    latestPledge: { date: "", name: "" },
  }),
};
</script>